import { Button } from '@mui/material'
import { Groups, Home, Mail, Settings, ShoppingBag } from '@mui/icons-material'
import { navigate } from 'gatsby'
import React from 'react'
import { routePaths } from '../../constants/routes'
import { useProfileContext } from '../../contexts/profileContext'
import { SubContainer } from '../common/Styled'
import Header from '../common/Header'
import { OptionsContainer } from './Styled'

const title = 'Admin'
const items = [
  {
    icon: <Home />,
    label: 'Accueil',
    route: routePaths.home
  },
  {
    icon: <Settings />,
    label: title
  }
]

const Admin = () => {
  const isAdmin = useProfileContext()?.isAdmin

  if (!isAdmin) return null

  return (
    <SubContainer>
      <Header {...{ title, items }} />
      <OptionsContainer>
        <Button
          variant="outlined"
          fullWidth
          color="secondary"
          onClick={() => navigate(routePaths.admin.orders)}
          startIcon={<ShoppingBag />}
        >
          Toutes les commandes
        </Button>
        <Button
          variant="outlined"
          fullWidth
          color="secondary"
          onClick={() => navigate(routePaths.admin.users)}
          startIcon={<Groups />}
        >
          Utilisateurs
        </Button>
        <Button
          variant="outlined"
          fullWidth
          color="secondary"
          onClick={() => navigate(routePaths.admin.contacts)}
          startIcon={<Mail />}
        >
          Demande de contact
        </Button>
      </OptionsContainer>
    </SubContainer>
  )
}

export default Admin
