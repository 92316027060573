import { styled } from '@mui/material/styles'

export const OptionsContainer = styled('div')`
  display: flex;
  gap: 20px;
  padding: 20px;
  table-layout: fixed;
  border-collapse: collapse;
  @media (max-width: 600px) {
    flex-wrap: wrap;
  }
`
