import React from 'react'

import AdminView from '../../components/Admin'
import SEO from '../../components/common/seo'

const Admin = () => (
  <>
    <SEO title="Admin" />
    <AdminView />
  </>
)

export default Admin
